import React from 'react';

import PageHeader from '../components/PageHeader';
//import TeamSlider from '../components/TeamSlider';
import Team from '../components/Team';
import ImageSlider from '../components/ImageSlider';
//import Button from '../components/Button';
import ButtonHref from '../components/ButtonHref';
import SectionHeading from '../components/SectionHeading';
//import TestimonialSlider from '../components/TestimonialSlider';
import BackgroundAnimation from '../components/BackgroundAnimation';
import faq from '../data/faq';

// images
import devality from '../../assets/images/logo-devality.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';
import about_image from '../../assets/images/about-image-2.jpg';
import AppFeatureSingle from '../components/AppFeatureSingle';

// data
import features from '../data/features';

const About = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Sobre Nosotros'
                pages={[
                    {
                        title: 'Inicio',
                        to: '/'
                    },
                    {
                        title: 'Sobre Nosotros',
                        to: '/nosotros'
                    }
                ]}
            />
            {/* page header - end */}

            {/* about section - start */}
            <div className="about-section">
                <div className="about-section-wrapper">
                    <div className="container">
                        {/* first half - start */}
                        <div className="row">
                            <div className="col-lg-6 offset-lg-0 order-lg-1 col-md-8 offset-md-2 col-10 offset-1 order-2">
                                <div className="about-section-content c-grey">
                                    <div className="section-heading">
                                        <div className="sub-heading c-blue upper ls-1">
                                            <i className="las la-user-circle"></i>
                                            <h5>introducción</h5>
                                        </div>
                                        <div className="main-heading c-dark">
                                            {/* <h1>Devality</h1> */}
                                            <img style={{height: "8rem"}} src={devality} alt="hero-phone" />
                                        </div>
                                    </div>
                                    <p className="paragraph-big">Devality es un equipo multidisciplinario de estudiantes de la Universidad Técnica Federico Santa María comprometidos con innovar.</p>
                                    <h4>Misión</h4>
                                    <p>Desarrollar soluciones de Software innovadoras para problemas complejos de ingeniería ligados al bienestar social.</p>
                                    <h4>Visión</h4>
                                    <p>Posicionarnos como referentes en proyectos ligados al bienestar social, animal y ambiental para empresas tecnológicas.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 offset-lg-0 order-lg-2 col-md-8 offset-md-2 col-10 offset-1 order-1">
                                <div className="about-section-image">
                                    <div className="pattern-image pattern-image-1">
                                        <div className="pattern-image-wrapper">
                                            <img className="drop-shadow-1" src={about_image} alt="pattern" />
                                            <div className="background-pattern background-pattern-radius drop-shadow-1">
                                                <BackgroundAnimation/>
                                                <div className="background-pattern-gradient"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* first half - end */}
                        {/* second half - start */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <h3 className="c-dark second-half-heading">¿Qué nos diferencia?</h3>
                            </div>
                        </div>
                        <div className="row gx-5 gy-5">
                            { features.map((element, key) => {
                                return (
                                    <div className="col-lg-4 offset-lg-0 col-md-6 offset-md-0 col-10 offset-1" key={key}>
                                        <AppFeatureSingle
                                            icon={element.icon}
                                            heading={element.heading}
                                            excerpt={element.excerpt}
                                            containerClass='app-feature-single-2'
                                            link={element.to}
                                        />
                                    </div>
                                );
                            }) }
                        </div>
                        {/* <div className="row gx-5 about-row">
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <AppFeatureSingle
                                    icon='las la-server'
                                    heading='Centralización'
                                    excerpt='Heat multiply is second divided fowl there is not man cattle they wherein lights greater concern open days tree is brought.'
                                    link='/features-2'
                                    containerClass='app-feature-single-1'
                                />
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <AppFeatureSingle
                                    icon='las la-filter'
                                    heading='Filtro manual'
                                    excerpt='Heat multiply is second divided fowl there is not man cattle they wherein lights greater concern open days tree is brought.'
                                    link='/features-2'
                                    containerClass='app-feature-single-1'
                                />
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <AppFeatureSingle
                                    icon='las la-cogs'
                                    heading='Filtro automatizado'
                                    excerpt='Heat multiply is second divided fowl there is not man cattle they wherein lights greater concern open days tree is brought.'
                                    link='/features-2'
                                    containerClass='app-feature-single-1'
                                />
                            </div>
                        </div> */}
                        {/* second half - end */}
                    </div>
                </div>
            </div>
            {/* about section - end */}

            {/* team section - start */}
            <div className="team">
                <div className="team-wrapper">
                    <div className="team-inner">
                        <div className="container">
                            {/* team section heading - start */}
                            <div className="row">
                                <div className="col">
                                    <div className="section-heading center c-white">
                                        <div className="sub-heading upper ls-1">
                                            <i className="las la-tags"></i>
                                            <h5>Nuestro equipo</h5>
                                        </div>
                                        <div className="main-heading">
                                            <h1>Conoce a Devality.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* team section heading - end */}
                        </div>
                        <div className="container team-slider-container">
                            {/* team - start */}
                            <div className="row">
                                <div className="col">
                                    <Team/>
                                </div>
                            </div>
                            {/* team - end */}
                        </div>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* team section - end */}

            {/* testimonial section - start */}
            {/* <div className="testimonial-section">
                <div className="testimonial-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-comments'
                                    heading='feedbacks'
                                    subHeading='What people are talking about.'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <TestimonialSlider/>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* testimonial section - end */}

            {/* faq section - start */}
            <div className="faq-section faq-section-2">
                <div className="faq-section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-10 offset-1">
                                <SectionHeading
                                    icon='las la-file-alt'
                                    heading='descubrir'
                                    subHeading='Algunas preguntas frecuentes'
                                    additionalClasses='center width-64'
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-9 col-md-8 col-10">
                                <div className="faq-wrapper">
                                    <div className="faq" id="faq-accordion">
                                        {
                                            faq.map((element, key) => {
                                                return (
                                                    <div className="accordion-item" key={key}>
                                                        <div className="accordion-header" id={`faq-${key}`}>
                                                            <button
                                                            className={`accordion-button ${key !== 1 ? 'collapsed' : ''}`}
                                                            type="button" 
                                                            data-bs-toggle="collapse" 
                                                            data-bs-target={`#faq-collapse-${key}`} 
                                                            aria-expanded={key === 1 ? 'true': 'false'}
                                                            aria-controls={`faq-collapse-${key}`}>
                                                                <span>{element.question}</span>
                                                            </button>
                                                        </div>
                                                        <div 
                                                        id={`faq-collapse-${key}`} 
                                                        className={`accordion-collapse collapse ${key === 1 ? 'show' : ''}`}
                                                        aria-labelledby={`faq-${key}`} 
                                                        data-bs-parent="#faq-accordion">
                                                            <div className="accordion-body">
                                                                <p>{element.answer}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* faq section - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                            <ButtonHref
                                linkHref='https://www.instagram.com/samfind.app/'
                                content='Síguenos en Instagram'
                                type='button-premium'
                                icon={<i className="fab fa-instagram"></i>}
                            />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default About;
