import React from 'react';

import PageHeader from '../components/PageHeader';
import SectionHeading from '../components/SectionHeading';
import ContactForm1 from '../components/ContactForm1';
import ImageSlider from '../components/ImageSlider';
//import Button from '../components/Button';
import ButtonHref from '../components/ButtonHref';

const Contact = () => {
    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Contactanos'
                pages={[
                    {
                        title: 'Inicio',
                        to: '/'
                    },
                    {
                        title: 'Contacto',
                        to: '/contacto'
                    }
                ]}
            />
            {/* page header - end */}

            {/* contact details - start */}
            <div className="contact-details">
                <div className="contact-details-wrapper">
                    <div className="container">
                        {/* contact details heading - start */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <SectionHeading
                                    subHeading='Formas de comunicarnos.'
                                    heading='Ponerse en contacto'
                                    icon='las la-handshake'
                                    additionalClasses='center width-55'
                                />
                            </div>
                        </div>
                        {/* contact details heading - end */}
                        {/* contact details row - start */}
                        <div className="row gx-5 details-row">
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                    <div className="app-feature-single-wrapper">
                                        <a href="https://www.facebook.com/SamFindApp" className="link-underline link-underline-1" target="_blank" rel='noreferrer'>
                                            <div className="icon">
                                                <i className="fab fa-facebook"></i>
                                            </div>
                                            <br></br>
                                            <span className="c-dark2">SamFindApp</span>
                                            <br></br><br></br>
                                        </a>            
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                    <div className="app-feature-single-wrapper">
                                        <a href="mailto:samfind.devality@gmail.com" className="link-underline link-underline-1">
                                            <div className="icon">
                                                <i className="las la-envelope-open"></i>
                                            </div>
                                            <br></br>
                                            <span className="c-dark2">samfind.devality@gmail.com</span>
                                            <br></br><br></br>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="app-feature-single app-feature-single-1">
                                    <div className="app-feature-single-wrapper">
                                        <a href="https://www.instagram.com/samfind.app/" className="link-underline link-underline-1" target="_blank" rel='noreferrer'>
                                            <div className="icon">
                                                <i className="fab fa-instagram"></i>
                                            </div>
                                            <br></br>
                                            <span className="c-dark2">SamFind.App</span>
                                            <br></br><br></br>
                                        </a>   
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* contact details row - end */}
                    </div>
                </div>
            </div>
            {/* contact details - end */}

            {/* contact form section - start */}
            <div className="contact-form-section">
                <div className="contact-form-section-wrapper">
                    <div className="container">
                        <div className="row gx-5 contact-form-section-row">
                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-10 offset-1">
                                {/* contact form - start */}
                                <ContactForm1/>
                                {/* contact form - end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact form section - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                            <ButtonHref
                                linkHref='https://www.instagram.com/samfind.app/'
                                content='Síguenos en Instagram'
                                type='button-premium'
                                icon={<i className="fab fa-instagram"></i>}
                            />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default Contact;
