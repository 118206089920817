import React from 'react';

//import { Link } from 'react-router-dom';

import PageHeader from '../components/PageHeader';
import RelatedPostSlider from '../components/RelatedPostSlider';
// import CommentSingle from '../components/CommentSingle';
// import comments from '../data/comments';
import BackgroundAnimation from '../components/BackgroundAnimation';
import ImageSlider from '../components/ImageSlider';
//import Button from '../components/Button';
import ButtonHref from '../components/ButtonHref';

// images
import thumbnail from '../../assets/images/blog/blog-read-img-1.jpg';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const BlogDetail1 = () => {

    // const getCommentsLength = (comment) => {
    //     let count = comment.length;
    //     comment.forEach(element => {
    //         if ( element.reply )
    //             count += getCommentsLength(element.reply)
    //     });
    //     return count;
    // } 

    // const commentDisplay = (comment, index) => {
    //     if (comment.reply) {
    //         return (
    //             <CommentSingle
    //                 key={index}
    //                 image={comment.image}
    //                 name={comment.name}
    //                 date={comment.date}
    //                 comment={comment.comment}
    //                 isReply={true}
    //             >
    //                 {
    //                     comment.reply ? comment.reply.map((reply, i) => {
    //                         return commentDisplay(reply, i);
    //                     }): null
    //                 }
    //             </CommentSingle>
    //         )
    //     }
    //     return (
    //         <CommentSingle
    //             key={index}
    //             image={comment.image}
    //             name={comment.name}
    //             date={comment.date}
    //             comment={comment.comment}
    //             isReply={true}
    //         />
    //     );
    // }

    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Nuestras noticias'
                pages={[
                    {
                        title: 'Inicio',
                        to: '/'
                    },
                    {
                        title: 'Noticias',
                        to: '/noticias'
                    }
                ]}
            />
            {/* page header - end */}

            {/* blog detail - start */}
            <div className="blog-detail">
                <div className="blog-detail-wrapper">
                    {/* blog detail content - start */}
                    <div className="container">
                        {/* blog detail thumbnail - start */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <div className="blog-detail-thumbnail drop-shadow">
                                    <img src={thumbnail} alt="blog-detail-thumbnail" />
                                </div>
                            </div>
                        </div>
                        {/* blog detail thumbnail - end */}
                        {/* blog detail content - start */}
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="blog-detail-content c-grey">
                                    <h1 className="c-dark f-w-700">Competencia de Transformación Digital.</h1>
                                    <div className="blog-single-details">
                                        <div className="date">
                                            <i className="las la-calendar"></i>
                                            21 de enero, 2022
                                        </div>
                                    </div>
                                    <p style={{textAlign: "justify"}}>Devality participará por medio de su proyecto SamFind en la Competencia de Transformación Digital 2022, surgida gracias a la Alianza Académica Scotiabank - Departamento de Informática de la Universidad Técnica Federico Santa María.</p>
                                    <p style={{textAlign: "justify"}}>El fin de esta competencia es abrir un espacio para que estudiantes en los diferentes años de sus carreras desarrollen proyectos de transformación digital que contribuyan a los Objetivos de Desarrollo Sustentable (ODS) de las Naciones Unidas.</p>
                                    <p style={{textAlign: "justify"}}>A su vez, su objetivo principal es desarrollar en las y los participantes la capacidad de innovación centrada en la resolución de problemas. Esto corresponde a un cambio de paradigma para las propuestas a fondos de innovación, las cuales tienden a proponer soluciones tecnológicas que carecen de adecuado entendimiento de los problemas que intentan solucionar. Por ende, para corregir dicha tendencia la presente competencia promueve el desarrollo de proyectos que caractericen adecuadamente el problema de información a resolver y propongan soluciones pertinentes al contexto en que la problemática existe.</p>
                                    <p style={{textAlign: "justify"}}>SamFind competirá dentro de la categoría "Objetivos de Desarrollo Sostenible (ODS) de las Naciones Unidas", en donde se debe abordar un problema relacionado al logro de uno de los objetivos de desarrollo sostenible definidos por la Organización de las Naciones Unidas.</p>
                                    {/* <div className="social social-gradient">
                                        <p className="paragraph-small c-grey-1">Share this post:</p>
                                        <ul>
                                            <li className="twitter">
                                                <Link to="#">
                                                    <i className="fab fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li className="facebook">
                                                <Link to="#">
                                                    <i className="fab fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li className="linkedin">
                                                <Link to="#">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </Link>
                                            </li>
                                            <li className="youtube">
                                                <Link to="#">
                                                    <i className="fab fa-youtube"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* blog detail content - end */}
                    </div>
                    {/* blog detail content - end */}
                </div>
            </div>
            {/* blog detail - end */}

            {/* related posts - start */}
            <div className="related-posts">
                <div className="related-posts-wrapper">
                    <div className="related-posts-inner">
                        <RelatedPostSlider/>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* related posts - end */}

            {/* comments - start */}
            {/* <div className="comments">
                <div className="comments-wrapper">
                    <div className="container"> */}
                        {/* comments list - start */}
                        {/* <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="comments-list"> */}
                                    {/* comments heading - start */}
                                    {/* <div className="comments-heading">
                                        <h3 className="c-dark f-w-700">{getCommentsLength(comments)} replies</h3>
                                    </div> */}
                                    {/* comments heading - end */}
                                    {/* {
                                        comments.map(commentDisplay)
                                    }
                                </div>
                            </div>
                        </div> */}
                        {/* comments list - end */}
                        {/* comments form - start */}
                        {/* <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="comments-form">
                                    <div className="comments-form-wrapper"> */}
                                        {/* comments form heading - start */}
                                        {/* <div className="comments-form-heading">
                                            <h3>Write a reply</h3>
                                        </div> */}
                                        {/* comments form heading - end */}
                                        {/* comments form - start */}
                                        {/* <form>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="name-field" type="text" placeholder="Name *" />
                                                        <label htmlFor="name-field">Name *</label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="email-field" type="email" placeholder="Email *" />
                                                        <label htmlFor="email-field">Email *</label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="website-field" type="text" placeholder="Website" />
                                                        <label htmlFor="website-field">Website</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating textarea-form">
                                                        <textarea className="input textarea form-control" id="comment-field" placeholder="Write reply *"></textarea>
                                                        <label htmlFor="comment-field">Write reply *</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <button type="submit" className="button button-3">
                                                        <span className="button-inner">
                                                            <span className="button-content">
                                                                <span className="text">Submit Reply</span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form> */}
                                        {/* comments form - end */}
                                    {/* </div>
                                </div>
                            </div>
                        </div> */}
                        {/* comments form - end */}
                    {/* </div>
                </div>
            </div> */}
            {/* comments - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                            <ButtonHref
                                linkHref='https://www.instagram.com/samfind.app/'
                                content='Síguenos en Instagram'
                                type='button-premium'
                                icon={<i className="fab fa-instagram"></i>}
                            />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default BlogDetail1;
