// ESTA LISTA ES DEL RESUMEN DEL HOME

const features = [
    {
        icon: 'las la-server',
        heading: 'Centralización',
        excerpt: 'Se reunen publicaciones de mascotas perdidas y encontradas de Facebook, Instagram y obvio SamFind en una sola app.',
        to: '/funcionalidades'
    },
    {
        icon: 'las la-filter',
        heading: 'Filtro manual',
        excerpt: 'Ingresa los datos característicos, necesarios y realistas para buscar a tu mascota o dueño de mascota en segundos.',
        to: '/funcionalidades'
    },
    {
        icon: 'las la-cogs',
        heading: 'Filtro automatizado',
        excerpt: 'Con tan solo una foto te entregaremos las publicaciones que mejor calcen con tu mascota perdida.',
        to: '/funcionalidades'
    }
];

export default features;