import React from 'react';

const ButtonHref = ({ linkHref, type, content, icon }) => {
    return (
        <a href={linkHref} target="_blank" rel='noreferrer' className={`button ${type}`}>
            <div className="button-inner">
                <div className="button-content">
                    {icon}
                    <h4>{content}</h4>
                </div>
            </div>
        </a>
    );
};

export default ButtonHref;
