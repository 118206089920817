import BackgroundAnimation from '../components/BackgroundAnimation';

// images
import featured_detailed_img_1 from '../../assets/images/feature-detailed-img-1.jpg';
import featured_detailed_img_2 from '../../assets/images/feature-detailed-img-2.jpg';
import featured_detailed_img_3 from '../../assets/images/feature-detailed-img-3.jpg';
import centralizacion from '../../assets/images/centralizacion.jpg';
import filtromanual from '../../assets/images/filtromanual.png';
import filtroautomatizado from '../../assets/images/filtro_automatizado.jpg';

const feature_tab = [
    {
        icon: "las la-server",
        title: "Centralización",
        content: (
            <>
                <p className="paragraph-big">La función que te hace acortar tiempos de búsquedas de tu mascota!😻</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={centralizacion} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>La centralización consiste en unificar los datos de distintas redes sociales. La primera red elegida es Facebook, donde se concentra un gran porcentaje de publicaciones de mascotas perdidas en grupos destinado a este tipo de información. La segundo red que utilizamos es Instagram, donde se concentra varias cuentas destinadas para publicaciones de mascotas perdidas y/o encontradas.</p>
                <p>Con esto le entregamos al dueño de una mascota perdida toda esta cantidad de datos que se encuentran dispersos en distintos medios, centralizado en una sola aplicación: <b>SamFind</b>.</p>
                <h3 className="c-dark">¿Por qué se eligieron estas redes sociales?</h3>
                <p>Le preguntamos a más de 200 personas que perdieron a mascotas a qué tipo de medio recurrieron, los tres medios más utilizados es primero salir a las calles a buscar a la mascota (claramente, todo partiríamos por ahí), luego, nos indicaron que si o si revisaban en grupos de Facebook o cuentas de instagram encargadas de publicar mascotas perdidas y/o encontradas.</p>
            </>
        )
    },
    {
        icon: "las la-filter",
        title: "Filtro manual",
        content: (
            <>
                <p className="paragraph-big">Configura con datos realistas para buscar a tu mascota (o a un dueño) dentro de una gran cantidad de datos almacenados en la web en segundos!</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={filtromanual} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>Esta función te permitira ingresar los datos más característicos de tu mascota, por ejemplo, su tendencia de raza, tamaño, edad aproximada, día de perdida, entre otras opciones más. Para que posteriormente te entreguemos todas las publicaciones relacionadas a tu filtro seleccionado.</p>
                <p>Lo maravilloso es que los resultados pueden ser de publicaciones realizadas dentro de SamFind o de publicaciones de Facebook o Instagram. Además, nosotros te entregaremos la información necesaria para que te puedas contactar con la persona que posiblemente encontró a tu mascota!!</p>
                <h3 className="c-dark">Stop! esto no es todo... 🙊🧐</h3>
                <p>Esto también servirá para personas que encontraron algún perro o gato en la calle y que quiera buscar a su posible dueño. ¿Cómo? Simplemente ingresa los datos aparente de la mascota encontrada y la app trabajará internamente para entregarte las publicaciones de sus posibles dueños. Genial, ¿cierto?.</p>
            </>
        )
    },
    {
        icon: "las la-cogs",
        title: "Filtro automatizado",
        content: (
            <>
                <p className="paragraph-big">Con tan solo una foto podrás obtener publicaciones de mascotas muy probables a tu tan querida y extrañada mascota.</p>
                <div className="pattern-image">
                    <div className="pattern-image-wrapper">
                        <img className="drop-shadow-1" src={filtroautomatizado} height="470" alt="pattern" />
                        <div className="background-pattern background-pattern-radius drop-shadow-1">
                            <BackgroundAnimation/>
                            <div className="background-pattern-gradient"></div>
                        </div>
                    </div>
                </div>
                <p>Este filtro permite que con tan solo subir una foto de tu mascota perdida te entreguemos todas las publicaciones de mascotas encontradas con gran porcentaje de similitud, es decir, nuestro SamRobot se encargará de buscar a tu mascota dentro de todas las publicaciones que se encuentran en redes sociales o en SamFind, entregándote las publicaciones con mayor porcentaje de similitud con respecto a la foto subida.</p>
                <h4 className="c-dark">¿La foto que se sube debe tener alguna característica?</h4>
                <p>Si! SamRobot necesita que tu foto sea de buena calidad, con esto se aumenta la posibilidad de poder encontrar tu mascota.</p>
                <h4 className="c-dark">¿Cuánto demorarán los resultados? ¿Días, horas, minutos? 😰</h4>
                <p>Sabemos que en el momento que se pierde una mascota cada minuto vale oro, es por esto que, nosotros nos comprometemos en tenerte los resultados en minutos, tú tranquilo, nosotros te avisaremos cuando esté listo.</p>
            </>
        )
    }
];

export default feature_tab;