import blog_thumbnail_1 from '../../assets/images/blog/blog-thumbnail-1.jpg';
import blog_thumbnail_2 from '../../assets/images/blog/blog-thumbnail-2.jpg';
import blog_thumbnail_3 from '../../assets/images/blog/blog-thumbnail-3.jpg';
import blog_thumbnail_4 from '../../assets/images/blog/blog-thumbnail-4.jpg';
// import blog_thumbnail_5 from '../../assets/images/blog/blog-thumbnail-5.jpg';
// import blog_thumbnail_6 from '../../assets/images/blog/blog-thumbnail-6.jpg';
// import blog_thumbnail_7 from '../../assets/images/blog/blog-thumbnail-7.jpg';
// import blog_thumbnail_8 from '../../assets/images/blog/blog-thumbnail-8.jpg';
// import blog_thumbnail_9 from '../../assets/images/blog/blog-thumbnail-9.jpg';
// import blog_thumbnail_10 from '../../assets/images/blog/blog-thumbnail-10.jpg';
// import blog_thumbnail_11 from '../../assets/images/blog/blog-thumbnail-11.jpg';
// import blog_thumbnail_12 from '../../assets/images/blog/blog-thumbnail-12.jpg';

const blog = [
    {
        thumbnail: blog_thumbnail_1,
        title: 'Competencia de Transformación Digital.',
        commentCount: 12,
        date: '21 de enero, 2022',
        excerpt: 'Devality participará en competencia surgida de la Alianza Académica Scotiabank - Departamento de Informática.',
        url: '/noticia-1',
    },
    {
        thumbnail: blog_thumbnail_2,
        title: 'Devality participa en bootcamp.',
        commentCount: 12,
        date: '1 de abril, 2022',
        excerpt: 'Devality comienza su participación en Competencia de Transformación Digital asistindo a bootcamp de Mind.',
        url: '/noticia-2',
    },
    {
        thumbnail: blog_thumbnail_3,
        title: 'SamFind competirá en la final.',
        commentCount: 12,
        date: '11 de mayo, 2022',
        excerpt: 'Devality es seleccionado como uno de los equipos finalistas en Competencia de Transformación Digital.',
        url: '/noticia-3',
    },
    {
        thumbnail: blog_thumbnail_4,
        title: 'Orgullosos: SamFind triunfa en la final.',
        commentCount: 12,
        date: '23 de junio, 2022',
        excerpt: 'Devality obtiene el primer lugar de la Competencia de Transformación Digital 2022 tras presentar su pitch final del proyecto SamFind.',
        url: '/noticia-4',
    }
    // {
    //     thumbnail: blog_thumbnail_5,
    //     title: 'Raving reviews about our latest algorithms.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_6,
    //     title: 'New features coming in 2020 to our app.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_7,
    //     title: 'New features coming in 2020 to our app.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_8,
    //     title: 'Raving reviews about our latest algorithms.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_9,
    //     title: 'New features coming in 2020 to our app.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_10,
    //     title: 'New features coming in 2020 to our app.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_11,
    //     title: 'Raving reviews about our latest algorithms.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // },
    // {
    //     thumbnail: blog_thumbnail_12,
    //     title: 'New features coming in 2020 to our app.',
    //     commentCount: 12,
    //     date: 'Dec 10, 2020',
    //     excerpt: 'Behold creature him whose own does not god seasons winged two will not bearing herein fowl fruitful they went there beginning ... ',
    //     url: '/blog-detail-1',
    // }
]

export default blog;