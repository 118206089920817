import React from 'react';

import { Link } from 'react-router-dom';

// images
import logo from '../../assets/images/logo.png';
import lofo_fesw from '../../assets/images/logo-fesw.png';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-row">
                                <div className="footer-detail">
                                    <Link to="/">
                                        <img src={logo} alt="footer-logo" />
                                    </Link>
                                    <p className="c-grey-1">SamFind es una aplicación móvil que busca reducir los tiempos de búsqueda de gatos y perros perdidos.</p>
                                    <div className="links">
                                        <a className="link-underline" href="mailto:samfind.devality@gmail.com">
                                            <span>samfind.devality@gmail.com</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="footer-list footer-social social-gradient">
                                    <h6>Síguenos</h6>
                                    <ul>
                                        <table>
                                            <thead></thead>
                                            {/* FACEBOOK */}
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <li className="facebook">
                                                        <a href="https://www.facebook.com/SamFindApp" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <i className="fab fa-facebook"></i>
                                                        </a>    
                                                    </li>
                                                </td>
                                                <td>
                                                    <li className="facebook">
                                                        <a href="https://www.facebook.com/SamFindApp" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <span>Facebook</span>
                                                        </a>
                                                    </li>
                                                </td>
                                            </tr>
                                            {/* INSTAGRAM */}
                                            <tr>
                                                <td>
                                                    <li className="linkedin">
                                                        <a href="https://www.instagram.com/samfind.app/" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </td>
                                                <td>
                                                    <li className="instagram">
                                                        <a href="https://www.instagram.com/samfind.app/" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <span>Instagram</span>
                                                        </a>
                                                    </li>
                                                </td>
                                            </tr>
                                            {/* YOUTUBE */}
                                            <tr>
                                                <td>
                                                    <li className="youtube">
                                                        <a href="https://www.youtube.com/channel/UCGl4lfC-EU8nt_hnPRE9WXA" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <i className="fab fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                </td>
                                                <td>
                                                    <li className="youtube">
                                                        <a href="https://www.youtube.com/channel/UCGl4lfC-EU8nt_hnPRE9WXA" className="link-underline" target="_blank" rel='noreferrer'>
                                                            <span>Youtube</span>
                                                        </a>
                                                    </li>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        {/* TERMINA */}

                                        {/* <li className="facebook">
                                            <a href="https://www.facebook.com/SamFindApp" className="link-underline" target="_blank" rel='noreferrer'>
                                                <i className="fab fa-facebook"></i>
                                                <span>Facebook</span>
                                            </a>    
                                        </li>
                                        <li className="linkedin">
                                            <a href="https://www.instagram.com/samfind.app/" className="link-underline" target="_blank" rel='noreferrer'>
                                                <i className="fab fa-instagram"></i>
                                                <span>Instagram</span>
                                            </a>
                                        </li>
                                        <li className="youtube">
                                            <a href="https://www.youtube.com/channel/UCGl4lfC-EU8nt_hnPRE9WXA" className="link-underline" target="_blank" rel='noreferrer'>
                                                <i className="fab fa-youtube"></i>
                                                <span>Youtube</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Menú</h6>
                                    <ul>
                                        <li>
                                            <Link to="nosotros" className="link-underline">
                                                <span>Sobre Nosotros</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="funcionalidades" className="link-underline">
                                                <span>Funcionalidades</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="noticias" className="link-underline">
                                                <span>Noticias</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Explora</h6>
                                    <ul>
                                        <li>
                                            <Link to="planes" className="link-underline">
                                                <span>Planes</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="contacto" className="link-underline">
                                                <span>Centro de ayuda</span>
                                            </Link>
                                        </li>
                                        <li className="youtube">
                                            <a href="https://www.google.com/maps/place/Av.+Espa%C3%B1a+1680,+Valpara%C3%ADso/@-33.0350319,-71.5976567,17z/data=!4m5!3m4!1s0x9689e0b5ee0b7187:0xacad1a0a9e723896!8m2!3d-33.0350319!4d-71.595468" className="link-underline" target="_blank" rel='noreferrer'>
                                                <span>Nuestra dirección</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-list">
                                    <h6>Somos parte de</h6>
                                    <ul>
                                        <li>
                                            <a href="https://www.feriadesoftware.cl/" className="link-underline" target="_blank" rel='noreferrer'>
                                                <img src={lofo_fesw} alt="footer-logo" width="200" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 offset-lg-0 col-md-8 offset-md-2 col-10 offset-1">
                            <div className="footer-copyright c-grey-1">
                                <h6>&copy; SamFind</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-pattern" style={{backgroundImage: `url(${background_image_1})`}}></div>
            </div>
        </footer>
    );
};

export default Footer;
