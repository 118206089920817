import React, { useState, useRef } from 'react';
import SectionHeading from './SectionHeading';
import emailjs from '@emailjs/browser';

const ContactForm1 = () => {

    const [senderName, setSenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [message, setMessage] = useState('');

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_ID)
        .then((result) => {
            //console.log(result.text);
            setSenderName('');
            setSenderEmail('');
            setMessage('');
        }, (error) => {
            //console.log(error.text);
        });
    }

    return (
        <div className="contact-form drop-shadow-2">
            <div className="contact-form-wrapper">
                <SectionHeading
                    icon='las la-envelope'
                    heading='contacto'
                    subHeading='Escribenos un mensaje'
                    additionalClasses='section-heading-2 center'
                />
                <form ref={form} onSubmit={sendEmail}>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="name-field" 
                        type="text"
                        name='senderName' 
                        placeholder="Nombre completo" 
                        value={senderName}
                        onChange={(e) => { setSenderName(e.target.value) }}
                        required
                        />
                        <label htmlFor="name-field">Nombre completo</label>
                    </div>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="email-field" 
                        type="text" 
                        name='senderEmail'
                        placeholder="Correo electrónico" 
                        value={senderEmail}
                        onChange={(e) => { setSenderEmail(e.target.value) }}
                        required
                        />
                        <label htmlFor="email-field">Correo electrónico</label>
                    </div>
                    <div className="form-floating">
                        <input 
                        className="input form-control" 
                        id="message-field" 
                        type="text" 
                        name='message'
                        placeholder="Mensaje" 
                        value={message}
                        onChange={(e) => { setMessage(e.target.value) }}
                        required
                        />
                        <label htmlFor="message-field">Mensaje</label>
                    </div>
                    <button type="submit" value="Send" className="button button-3">
                        <span className="button-inner">
                            <span className="button-content">
                                <span className="text">Enviar</span>
                            </span>
                        </span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm1;
