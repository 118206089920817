const faq = [
    {
        question: '¿Me podré comunicar dentro de la app a un dueño de una publicación en caso de encontrar a mi mascota?',
        answer: 'Dentro de la publicación no se podrá comunicar con el dueño de una publicación, pero te entregaremos la información necesaria para contactarse con ese usuario.'
    },
    {
        question: '¿A qué ciudades llega la app?',
        answer: 'Actualmente estamos operativos en la ciudad de Viña del Mar, Valparaíso. Pronto llegaremos a Santiago y más rincones de Chile.'
    },
    {
        question: '¿Necesito crearme una cuenta para ingresar a la app?',
        answer: 'Si! Necesitamos tener los datos de un usuario si necesita publicar para que se puedan contactar con él.'
    },
    // {
    //     question: 'Is there Orions branding in my app?',
    //     answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    // },
    // {
    //     question: 'Can I preview before I publish?',
    //     answer: 'Prepare questions that the patient should answer when registering for an online there consultation. We will send them to him before the visit in the form of survey to which he will also be able to attach his research results.'
    // }
];

export default faq;