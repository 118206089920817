import React from 'react';

//import { Link } from 'react-router-dom';

import PageHeader from '../components/PageHeader';
import RelatedPostSlider from '../components/RelatedPostSlider';
// import CommentSingle from '../components/CommentSingle';
// import comments from '../data/comments';
import BackgroundAnimation from '../components/BackgroundAnimation';
import ImageSlider from '../components/ImageSlider';
//import Button from '../components/Button';
import ButtonHref from '../components/ButtonHref';

// images
import thumbnail from '../../assets/images/blog/blog-read-img-4.jpg';
import background_image_1 from '../../assets/images/patterns/pattern-1.jpg';

const BlogDetail4 = () => {

    // const getCommentsLength = (comment) => {
    //     let count = comment.length;
    //     comment.forEach(element => {
    //         if ( element.reply )
    //             count += getCommentsLength(element.reply)
    //     });
    //     return count;
    // } 

    // const commentDisplay = (comment, index) => {
    //     if (comment.reply) {
    //         return (
    //             <CommentSingle
    //                 key={index}
    //                 image={comment.image}
    //                 name={comment.name}
    //                 date={comment.date}
    //                 comment={comment.comment}
    //                 isReply={true}
    //             >
    //                 {
    //                     comment.reply ? comment.reply.map((reply, i) => {
    //                         return commentDisplay(reply, i);
    //                     }): null
    //                 }
    //             </CommentSingle>
    //         )
    //     }
    //     return (
    //         <CommentSingle
    //             key={index}
    //             image={comment.image}
    //             name={comment.name}
    //             date={comment.date}
    //             comment={comment.comment}
    //             isReply={true}
    //         />
    //     );
    // }

    return (
        <>
            {/* page header - start */}
            <PageHeader
                title='Nuestras noticias'
                pages={[
                    {
                        title: 'Inicio',
                        to: '/'
                    },
                    {
                        title: 'Noticias',
                        to: '/noticias'
                    }
                ]}
            />
            {/* page header - end */}

            {/* blog detail - start */}
            <div className="blog-detail">
                <div className="blog-detail-wrapper">
                    {/* blog detail content - start */}
                    <div className="container">
                        {/* blog detail thumbnail - start */}
                        <div className="row">
                            <div className="col-lg-12 offset-lg-0 col-10 offset-1">
                                <div className="blog-detail-thumbnail drop-shadow">
                                    <img src={thumbnail} alt="blog-detail-thumbnail" />
                                </div>
                            </div>
                        </div>
                        {/* blog detail thumbnail - end */}
                        {/* blog detail content - start */}
                        <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="blog-detail-content c-grey">
                                    <h1 className="c-dark f-w-700">Orgullosos: SamFind triunfa en la final.</h1>
                                    <div className="blog-single-details">
                                        <div className="date">
                                            <i className="las la-calendar"></i>
                                            23 de junio, 2022
                                        </div>
                                    </div>
                                    <p style={{textAlign: "justify"}}>Devality obtiene el primer lugar en la fase final de la Competencia de Transformación Digital 2022, realizada por la Alianza Académica Scotiabank - Departamento de Informática de la Universidad Técnica Federico Santa María.</p>
                                    <p style={{textAlign: "justify"}}>En la etapa final de la competencia participaron los equipos Devality, Chipleki y Notepath, los cuales expusieron sus respectivos pitch al jurado.</p>
                                    <p style={{textAlign: "justify"}}>El pitch de SamFind fue presentado por Darinka Quiñones, Scrum Master de Devality. En este indicó la conexión con el usuario, potencial de efectividad, innovación, competitividad e impacto de la aplicación. Además, se profundizó en su prototipo y proyección.</p>
                                    <p style={{textAlign: "justify"}}>El prototipo de SamFind es una interfaz web sencilla en la que se puede cargar una imagen de un gato o un perro y se entrega como resultado una tarjeta en la que se identifica el tipo de animal, es decir, gato o perro y su raza. De este se explicó sus funcionalidades, flujo de vistas, escalabilidad y viabilidad.</p>
                                    <p style={{textAlign: "justify"}}>Tras haber expuesto los tres equipos finalistas el jurado deliberó y declaró a Devality y a su proyecto SamFind como ganador de la Competencia de Transformación Digital 2022.</p>
                                    <p style={{textAlign: "justify"}}>Finalmente, el equipo fue entrevistado por el Departamento de Informática para dar a conocer sus impresiones sobre su participación y obtención del primer lugar en la competencia. Para mayor información ingrese al siguiente <a className='c-dark3' href='https://www.inf.utfsm.cl/noticias/603-ganadores-segunda-version-ctd' target="_blank" rel='noreferrer'>enlace</a>.</p>
                                    {/* <div className="social social-gradient">
                                        <p className="paragraph-small c-grey-1">Share this post:</p>
                                        <ul>
                                            <li className="twitter">
                                                <Link to="#">
                                                    <i className="fab fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li className="facebook">
                                                <Link to="#">
                                                    <i className="fab fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li className="linkedin">
                                                <Link to="#">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </Link>
                                            </li>
                                            <li className="youtube">
                                                <Link to="#">
                                                    <i className="fab fa-youtube"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* blog detail content - end */}
                    </div>
                    {/* blog detail content - end */}
                </div>
            </div>
            {/* blog detail - end */}

            {/* related posts - start */}
            <div className="related-posts">
                <div className="related-posts-wrapper">
                    <div className="related-posts-inner">
                        <RelatedPostSlider/>
                    </div>
                    <div className="background-pattern background-pattern-1">
                        <BackgroundAnimation/>
                        <div className="background-pattern-gradient"></div>
                        <div className="background-pattern-bottom">
                            <div className="image" style={{backgroundImage: `url(${background_image_1})`}}></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* related posts - end */}

            {/* comments - start */}
            {/* <div className="comments">
                <div className="comments-wrapper">
                    <div className="container"> */}
                        {/* comments list - start */}
                        {/* <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="comments-list"> */}
                                    {/* comments heading - start */}
                                    {/* <div className="comments-heading">
                                        <h3 className="c-dark f-w-700">{getCommentsLength(comments)} replies</h3>
                                    </div> */}
                                    {/* comments heading - end */}
                                    {/* {
                                        comments.map(commentDisplay)
                                    }
                                </div>
                            </div>
                        </div> */}
                        {/* comments list - end */}
                        {/* comments form - start */}
                        {/* <div className="row">
                            <div className="col-md-8 offset-md-2 col-10 offset-1">
                                <div className="comments-form">
                                    <div className="comments-form-wrapper"> */}
                                        {/* comments form heading - start */}
                                        {/* <div className="comments-form-heading">
                                            <h3>Write a reply</h3>
                                        </div> */}
                                        {/* comments form heading - end */}
                                        {/* comments form - start */}
                                        {/* <form>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="name-field" type="text" placeholder="Name *" />
                                                        <label htmlFor="name-field">Name *</label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="email-field" type="email" placeholder="Email *" />
                                                        <label htmlFor="email-field">Email *</label>
                                                    </div>
                                                    <div className="form-floating">
                                                        <input className="input form-control" id="website-field" type="text" placeholder="Website" />
                                                        <label htmlFor="website-field">Website</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-floating textarea-form">
                                                        <textarea className="input textarea form-control" id="comment-field" placeholder="Write reply *"></textarea>
                                                        <label htmlFor="comment-field">Write reply *</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <button type="submit" className="button button-3">
                                                        <span className="button-inner">
                                                            <span className="button-content">
                                                                <span className="text">Submit Reply</span>
                                                            </span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form> */}
                                        {/* comments form - end */}
                                    {/* </div>
                                </div>
                            </div>
                        </div> */}
                        {/* comments form - end */}
                    {/* </div>
                </div>
            </div> */}
            {/* comments - end */}

            {/* instagram - start */}
            <div className="instagram">
                <div className="instagram-wrapper">
                    <ImageSlider/>
                    <div className="button-wrapper">
                            <ButtonHref
                                linkHref='https://www.instagram.com/samfind.app/'
                                content='Síguenos en Instagram'
                                type='button-premium'
                                icon={<i className="fab fa-instagram"></i>}
                            />
                    </div>
                </div>
            </div>
            {/* instagram - end */}
        </>
    );
};

export default BlogDetail4;