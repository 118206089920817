import React from 'react';

// images
//import team_img_3 from '../../assets/images/testimonial-slide-img-3.png';
//import team_img_2 from '../../assets/images/testimonial-slide-img-2.png';
//import team_img_1 from '../../assets/images/testimonial-slide-img-1.png';

const Team = () => {

    const data = [
        {
            image: 'https://media-exp1.licdn.com/dms/image/C4E03AQF0ULjL_xFgPA/profile-displayphoto-shrink_800_800/0/1662160600625?e=1674086400&v=beta&t=jMCH39ZPNnKFP2A7baoEl5VYMJBc-za9QppgFcTqZm8',
            name: 'Darinka Quiñones',
            designation: 'Scrum Master',
            social: [
                {
                    to: 'https://github.com/darinkaqt',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/darinkaqt/',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        },
        {
            image: 'https://media-exp1.licdn.com/dms/image/C4E03AQFsrbEJ2-wOTw/profile-displayphoto-shrink_800_800/0/1650037752120?e=1674086400&v=beta&t=bWFUjv65RsgALeauBwdGqJJmnrRJ3fYwnCmoYCFPGk0',
            name: 'Pablo Estay',
            designation: 'Product Owner',
            social: [
                {
                    to: 'https://github.com/Pestay',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/pestay/',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        },
        {
            image: 'https://media-exp1.licdn.com/dms/image/C4E03AQEJtsWbILvu1w/profile-displayphoto-shrink_800_800/0/1661827399665?e=1674086400&v=beta&t=szASvycA9QdL8WG6ue9D_vJsIuYFVK2ErfSUmm-j55s',
            name: 'Marcelo Panire',
            designation: 'Community Manager',
            social: [
                {
                    to: 'https://github.com/MarceloPanire',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/marcelo-panire/',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        },
        {
            image: 'https://media-exp1.licdn.com/dms/image/D4E35AQFIVVPrh0KvLQ/profile-framedphoto-shrink_800_800/0/1667835630942?e=1669143600&v=beta&t=lRNgos1DNEbmJNBbAJ3uIDhEn3zNvpeAWqLkeMdb3cc',
            name: 'Felipe Quintanilla',
            designation: 'IA Developer',
            social: [
                {
                    to: 'https://github.com/Feldoy',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/fquintanilla',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        },
        {
            image: 'https://media-exp1.licdn.com/dms/image/C4D03AQGWQhaxudEtbA/profile-displayphoto-shrink_800_800/0/1637681224968?e=1674086400&v=beta&t=jzVMR0l2Mbwo_gMtiAa_b8_RGrLxrhubvLnjC4dn3kI',
            name: 'Ignacio Ulloa',
            designation: 'Backend Developer',
            social: [
                {
                    to: 'https://github.com/srlaggy',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/ignacioulloahuenul/',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        },
        {
            image: 'https://media-exp1.licdn.com/dms/image/D4E03AQH6QBsdofVm-w/profile-displayphoto-shrink_800_800/0/1661559405143?e=1674086400&v=beta&t=XA5ctPUWr3PNVGg7klGHr_EviooQaSQ4V0Ud4PIlN48',
            name: 'Raúl Cruz',
            designation: 'Frontend Developer',
            social: [
                {
                    to: 'https://github.com/RaulCruzT',
                    icon: 'fab fa-github'
                },
                {
                    to: 'https://www.linkedin.com/in/raulcruztorres',
                    icon: 'fab fa-linkedin-in'
                }
            ]
        }
    ]

    return (
        <div className="team-grid">
                {
                    data.map((member, key) => {
                        return (
                            <div key={key}>
                                <div className="team-single drop-shadow-team-1">
                                    <div className="team-single-wrapper">
                                        <div className="image">
                                            <div className="image-wrapper">
                                                <div className="image-inner">
                                                    <img src={member.image} alt="team-member" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3>{member.name}</h3>
                                        <p>{member.designation}</p>
                                        <div className="social social-1">
                                            <ul>
                                                {
                                                    member.social.map((single, innerkey) => {
                                                        return (
                                                            <li key={innerkey}>
                                                                <a href={single.to} target="_blank" rel='noreferrer'>
                                                                    <i className={single.icon}></i>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
        </div>
    );
};

export default Team;